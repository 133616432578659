import React from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import validate from "./validate";
import entries from "../server/entries";
import { connect } from "react-redux";

class VolunteerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      volunteerRoles: [],
    };
  }

  componentDidMount() {
    var { soccerField } = this.props.formStates;

    var soccerFieldComponents = soccerField.split(" (");
    const soccerFieldName = soccerFieldComponents[0];
    entries
      .get(
        `/api/volunteer_roles_available_for_field_by_name/${soccerFieldName}`
      )
      .then((response) => {
        console.log("volunteer roles:" + response.data);
        this.setState({ volunteerRoles: response.data });
      });
  }

  renderVolunteerRoleSelector = ({ input, meta: { touched, error } }) => (
    <div>
      <select {...input}>
        <option value="">Select a volunteer role</option>
        {this.state.volunteerRoles.map((val) => (
          <option value={val} key={val}>
            {val}
          </option>
        ))}
      </select>
      {touched && error && <span>{error}</span>}
    </div>
  );

  render() {
    const { handleSubmit, pristine, previousPage, submitting } = this.props;
    const { soccerField } = this.props.formStates;
    return (
      <center>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <p className="volunteerWarning">
              <strong>Everyone must volunteer</strong> so that we can run FISHE
              Soccer.
            </p>

            <label>Volunteer Roles for {soccerField}</label>
            <Field
              name="volunteerRole"
              component={this.renderVolunteerRoleSelector}
            />
          </div>
          <br />
          <div>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="btn btn-success"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
            </button>
          </div>
        </form>
      </center>
    );
  }
}

VolunteerPage = connect((state) => ({
  formStates: getFormValues("wizard")(state),
}))(VolunteerPage);

export default reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(VolunteerPage);
