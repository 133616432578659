import entries from "../server/entries";
import history from "../history";
import { countChildren, countTshirts } from "../utils/cost_calculator";

export const createEntry = async (formValues, dispatch) => {
  const response = await entries.post(`/api/register`, { ...formValues });

  // TODO if response is not 201, then report an error and don't dispatch
  // perhaps we can use validation to report an error?

  console.log("API response:", response);

  dispatch({ type: "CREATE_ENTRY", payload: response.data });
  // calculate child and tshirt count from formValues
  const childCount = countChildren({ ...formValues });
  const tshirtCount = countTshirts({ ...formValues });

  // Don't allow the user to go back to their original form, because they might resubmit and create a duplicate
  history.replace(`/completed/${childCount}/${tshirtCount}`);
};
