import React from "react";
import { Router, Route } from "react-router-dom";
import history from "../history";
import SignupForm from "./SignupForm";
import Completed from "./Completed";
import SignupFormTest from "./SignupFormTest";
import WizardForm from "./WizardForm";
import { createEntry } from "../actions";

class App extends React.Component {
  submitForm = (formValues, dispatch) => {
    console.log("submitting to api");
    createEntry(formValues, dispatch);
  };

  render() {
    return (
      <div>
        <nav class="navbar bg-success text-white">
          <div class="container-fluid">
            <div class="navbar-header">
              <a class="navbar-brand text-white" href="#top">
                FISHE Soccer Registration: Spring 2024
              </a>
            </div>
          </div>
        </nav>
        {/* <Router history={history}>
          <div>
            <Route
              path="/"
              exact
              children={<WizardForm onSubmit={this.submitForm}></WizardForm>}
            />
            <Route path="/old" exact component={SignupForm} />
            <Route
              path="/completed/:childCount/:tshirtCount"
              component={Completed}
            />
            <Route path="/test" exact component={SignupFormTest} />
          </div>
        </Router> */}
        
        {/* <div class="alert alert-danger">
        Undergoing maintenance - registration will be back later today
        </div> */}

        <div class="alert alert-danger">
          Registration for Spring 2024 is now closed.
        </div>
        <div>
        <a
          class="btn btn-primary btn-lg"
          role="button"
          href="https://forms.gle/vTcAt5CtW4DwAyq16"
          >Sign up to be notified when FISHE soccer opens up again for the Fall 2024 season.</a>
        </div>
       
      </div>
    );
  }
}

export default App;
