import React, { Component } from "react";
import SignupForm from "./SignupForm";
import VolunteerPage from "./VolunteerPage";

class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
    };
  }
  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { onSubmit } = this.props;

    const { page } = this.state;
    return (
      <div>
        {page === 1 && <SignupForm onSubmit={this.nextPage} />}
        {page === 2 && (
          <VolunteerPage previousPage={this.previousPage} onSubmit={onSubmit} />
        )}
      </div>
    );
  }
}

// TODO see if this is worth it
// WizardForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default WizardForm;
