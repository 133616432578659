import React from "react";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";

import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

let renderTshirtDropdown = ({ input, meta: { touched, error } }) => {
  const keyAndFields = {
    YS: "Youth Small",
    YM: "Youth Medium",
    YL: "Youth Large",
    AS: "Adult Small",
    AM: "Adult Medium",
    AL: "Adult Large",
    AXL: "Adult Extra Large",
  };
  return (
    <div>
      <p>
        If you would like to purchase a FISHE shirt for your child, you can
        indicate that here. Purchasing shirts is not required; you may wear a
        shirt of the correct color for your field that you already own. We do
        not put names or numbers on the shirts, but you can take your shirts to
        a sporting goods store and have them put on yourself.
      </p>
      <select {...input}>
        <option value="None">
          Optional: select a tshirt size. Tshirts are $15 each.
        </option>
        {Object.entries(keyAndFields).map((keyAndField) => (
          <option value={keyAndField[0]} key={keyAndField[0]}>
            {keyAndField[1]}
          </option>
        ))}
      </select>
      <div className="text-danger">
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let renderIsPlaying = (child) => {
  return (
    <div>
      <label>
        <p>
          Team by Age Group (if you would like your child to play down an age
          group[i.e. having an 8 year old play U8], you must first get
          permission from the Field Coordinator for your field. All kids may
          play up an age group with their parents' permission.
        </p>
      </label>

      <Field
        name={`${child}.teamByAgeGroup`}
        component={renderTeamByAgeGroupSelector}
      />
    </div>
  );
};

let renderTeamByAgeGroupSelector = ({ input, meta: { touched, error } }) => {
  const keyAndFields = {
    U6: "U6 (this team is for 4 and 5 year old children)",
    U8: "U8 (this team is for 6 and 7 year old children)",
    U10: "U10 (this team is for 8 and 9 year old children)",
    U12: "U12 (this team is for 10 and 11 year old children)",
    U14: "U14 (this team is for 12 and 13 year old children)",
    U19: "U19 (this team is for 14 to 19 year olds)",
  };
  return (
    <div>
      <select {...input}>
        <option value="">Select a team</option>
        {Object.entries(keyAndFields).map((keyAndField) => (
          <option value={keyAndField[0]} key={keyAndField[0]}>
            {keyAndField[1]}
          </option>
        ))}
      </select>
      <div className="text-danger">
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let renderError = ({ meta: { touched, error } }) => {
  return (
    <div className="text-danger">
      {touched && error ? <span>{error}</span> : false}
    </div>
  );
};

let renderFieldSelector = ({ input, meta: { touched, error } }) => {
  const fields = [
    "Pine Ridge (Annandale)",
    "Poplar Tree (Chantilly)",
    "South Run (Springfield)",
    "Nike (Great Falls/Reston)"
  ];
  return (
    <div>
      <select {...input}>
        <option value="">Select a field</option>
        {fields.map((field) => (
          <option value={field} key={field}>
            {field}
          </option>
        ))}
      </select>
      <div className="text-danger">
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let renderField = ({ input, label, type, meta: { touched, error } }) => {
  return (
    <div className="field">
      <label className="formLabel">{label}</label>
      <input {...input} type={type} placeholder={label} autoComplete="no" />
      <div className="text-danger">
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let Child = ({ child, fields, index, isPlaying }) => {
  return (
    <li key={index} className="form-group">
      <h4 className="ui dividing header">Child #{index + 1}</h4>
      <label>
        Will this child be playing or does this child need babysitting?
      </label>
      <br />
      <label className="yesNoLabel">
        <Field
          name={`${child}.position`}
          component="input"
          type="radio"
          value="playing"
        />{" "}
        Playing{" "}
      </label>
      <label>
        <Field
          name={`${child}.position`}
          component="input"
          type="radio"
          value="needsBabysitting"
        />{" "}
        Needs Babysitting
      </label>
      <Field name={`${child}.position`} component={renderError} />
      {isPlaying && renderIsPlaying(child)}

      <Field
        name={`${child}.firstName`}
        type="text"
        component={renderField}
        label="First Name"
      />
      <Field
        name={`${child}.lastName`}
        type="text"
        component={renderField}
        label="Last Name"
      />
      <Field
        name={`${child}.birthDate`}
        type="date"
        component={renderField}
        label="Date of Birth"
      />
      <label>Sex</label>
      <br />
      <label className="yesNoLabel">
        <Field
          name={`${child}.sex`}
          component="input"
          type="radio"
          value="male"
        />{" "}
        Male
      </label>
      <label>
        <Field
          name={`${child}.sex`}
          component="input"
          type="radio"
          value="female"
        />{" "}
        Female
      </label>
      <Field name={`${child}.sex`} component={renderError} />
      <br />

      <Field name={`${child}.shirtSize`} component={renderTshirtDropdown} />
      <br />

      <label>Medical Information/Allergies</label>
      <br />
      <Field
        name={`${child}.medicalInfo`}
        component="textarea"
        placeholder="Medical Information/Allergies"
      />

      <br />

      <button
        type="button"
        onClick={() => fields.push({})}
        className="btn btn-primary"
      >
        Add another Child
      </button>
      <button
        type="button"
        title="Remove Child"
        onClick={() => {
          confirmAlert({
            title: "FISHE Soccer Registration",
            message: "Are you sure you want to remove this child?",
            buttons: [
              {
                label: "Yes",
                onClick: () => fields.remove(index),
              },
              {
                label: "No",
                onClick: () => console.log("Cancelled removing of a child"),
              },
            ],
          });
        }}
        className="btn btn-danger"
      >
        Remove Child
      </button>
    </li>
  );
};

const selector = formValueSelector("wizard");
Child = connect((state, props) => ({
  isPlaying: selector(state, `${props.child}.position`) === "playing",
}))(Child);

/**
* Mapping of closed teams: age groups to field names.
* @type {Object.<string, string>}
*/
const ageGroupToFieldMapping = {
  U19: ["South Run (Springfield)", "Poplar Tree (Chantilly)"],
  U8: ["South Run (Springfield)"],
  U10: ["South Run (Springfield)"],
  U12: ["South Run (Springfield)", "Pine Ridge (Annandale)", "Poplar Tree (Chantilly)"],
  U6: ["South Run (Springfield)", "Pine Ridge (Annandale)"],
  U14: ["South Run (Springfield)", "Poplar Tree (Chantilly)"],
};

// Get all the closed fields and sort them by field name.
const closedFields = Object.values(ageGroupToFieldMapping).flat().sort();

// create a string with all of the closed teams
// String should look like: "field ageGroup, ageGroup". For example: "Pine Ridge U12, U14"
// Fields should not be repeated. For example: "Pine Ridge U12, U14, South Run U8"
const closedTeams = closedFields.reduce((acc, field, index) => {
  const ageGroups = Object.keys(ageGroupToFieldMapping).filter(
    (ageGroup) => ageGroupToFieldMapping[ageGroup].includes(field)
  );
  if (ageGroups.length) {
    if (!acc.includes(field)) {
      acc += `${field} ${ageGroups.join(", ")}`;
      if (index < closedFields.length - 1) {
        acc += ", ";
      }
    }
  }
  return acc;
}, "");

class SignupForm extends React.Component {
  renderAddress = (parent, index) => {
    if (index === 0) {
      return (
        <div>
          <h5>Address</h5>
          <Field
            name={`${parent}.addressStreet`}
            type="text"
            component={renderField}
            label="Street"
          />
          <Field
            name={`${parent}.addressCity`}
            type="text"
            component={renderField}
            label="City"
          />
          <Field
            name={`${parent}.addressState`}
            type="text"
            component={renderField}
            label="State"
          />
          <Field
            name={`${parent}.addressZip`}
            type="text"
            component={renderField}
            label="Zip Code"
          />
        </div>
      );
    }
  };

  renderParents = ({ fields }) => {
    if (fields.length < 2) {
      fields.push({});
    }

    return (
      <ul>
        {fields.map((parent, index) => (
          <li key={index} className="form-group">
            <h3 className="ui dividing header">Parent #{index + 1}</h3>

            <Field
              name={`${parent}.firstName`}
              type="text"
              component={renderField}
              label="First Name"
            />

            <Field
              name={`${parent}.lastName`}
              type="text"
              component={renderField}
              label="Last Name"
            />

            <Field
              name={`${parent}.phone`}
              type="tel"
              component={renderField}
              label="Phone Number"
            />

            {this.renderAddress(parent, index)}
          </li>
        ))}
      </ul>
    );
  };

  renderChildren = ({ fields, meta: { error, submitFailed } }) => {
    // Ensure there is at least one child
    if (fields.length < 1) {
      fields.push({});
    }

    return (
      <div>
        <ul>
          {fields.map((child, index) => (
            <Child child={child} fields={fields} index={index} />
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <center>
        {closedTeams && (
          <div className="alert alert-danger">
            <h1>Important: These teams are closed because they are full: {closedTeams}</h1>
          </div>
        )}
        <form onSubmit={handleSubmit} className="ui error">
          <Field
            name="primary_email"
            component={renderField}
            type="email"
            label="Email"
          />
          <Field
            name="retype_email"
            component={renderField}
            type="email"
            label="Confirm Email"
          />
          <FieldArray name="parents" component={this.renderParents} />
          <FieldArray name="children" component={this.renderChildren} />

          <h3 className="ui dividing header">Emergency Contact Details</h3>
          <Field
            name="emergencyContactName"
            component={renderField}
            type="text"
            label="Name"
          />
          <Field
            name="emergencyContactPhone"
            component={renderField}
            type="tel"
            label="Phone Number"
          />

          <h3 className="ui dividing header">Additional Questions:</h3>
          {renderYesNoWithLabel(
            "fairfaxCountyResident",
            "Do you live in Fairfax County?"
          )}
          <Field name="fairfaxCountyResident" component={renderError} />

          {renderYesNoWithLabel(
            "militaryFamily",
            "Is your family in the military?"
          )}
          <Field name="militaryFamily" component={renderError} />

          <label>Which field will your family be playing at?</label>
          <Field
            name="soccerField"
            component={renderFieldSelector}
            type="hidden"
          />

          <label>
            <a
              href="https://docs.google.com/document/d/1xmp3f_yXqW-Bj_qItyZIxzuJRDOlB8KDNdUh6ue0sfI/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Statement of Faith / Nicene Creed
            </a>
            <p>
              I HAVE read the above Statement of Faith / Nicene Creed AND, I
              agree to abide by these statements in my conduct and speech.
            </p>
          </label>
          <br />
          {renderYesNo("statementOfFaith")}
          <Field name="statementOfFaith" component={renderError} />

          <label>
            Do you agree to the
            <a
              href="https://docs.google.com/document/d/1yKoOV-T-r-9AbWH1BbCO8Ja3u6qhSy17r6Wb-pmFnVc/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Code of Conduct and Medical Release?
            </a>
          </label>
          {renderYesNo("codeOfConduct")}
          <Field name="codeOfConduct" component={renderError} />

          <br />
          <button className="btn btn-success" disabled={this.props.submitting}>
            Next
          </button>
        </form>
        <p>
          If you have questions, please email{" "}
          <a href="mailto:fishehomeschool@gmail.com">
            fishehomeschool@gmail.com
          </a>
        </p>
      </center>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.primary_email) {
    errors.primary_email = "You must enter an email";
  }

  if (!formValues.retype_email) {
    errors.retype_email = "Please confirm your email";
  }

  if (formValues.retype_email != formValues.primary_email) {
    errors.retype_email = "Emails do not match";
  }

  if (!formValues.children || !formValues.children.length) {
    errors.children = { _error: "At least one child must be entered" };
  } else {
    const childrenArrayErrors = [];
    formValues.children.forEach((child, childIndex) => {
      const childErrors = {};
      if (!child || !child.firstName) {
        childErrors.firstName = "You must enter a name";
        childrenArrayErrors[childIndex] = childErrors;
      }

      if (!child || !child.lastName) {
        childErrors.lastName = "You must enter a name";
        childrenArrayErrors[childIndex] = childErrors;
      }

      if (!child || !child.birthDate) {
        childErrors.birthDate = "You must enter a birth date";
        childrenArrayErrors[childIndex] = childErrors;
      }

      if (!child || !child.sex) {
        childErrors.sex = "You must answer this question";
        childrenArrayErrors[childIndex] = childErrors;
      }

      if (!child || !child.teamByAgeGroup) {
        childErrors.teamByAgeGroup = "You must select a team";
        childrenArrayErrors[childIndex] = childErrors;
      }

      if (!child || !child.position) {
        childErrors.position =
          "You must choose either Playing or Needs Babysitting";
        childrenArrayErrors[childIndex] = childErrors;
      }
    });
    if (childrenArrayErrors.length) {
      errors.children = childrenArrayErrors;
    }
  }

  if (formValues.parents) {
    const returnArray = [];
    const parentErrors = {};
    if (!formValues.parents[0].firstName) {
      parentErrors.firstName = "You must enter a first name";
    }
    if (!formValues.parents[0].lastName) {
      parentErrors.lastName = "You must enter a last name";
    }
    if (!formValues.parents[0].phone) {
      parentErrors.phone = "You must enter a phone number";
    }
    if (!formValues.parents[0].addressStreet) {
      parentErrors.addressStreet = "You must enter a street";
    }
    if (!formValues.parents[0].addressCity) {
      parentErrors.addressCity = "You must enter a city";
    }
    if (!formValues.parents[0].addressState) {
      parentErrors.addressState = "You must enter a state";
    }
    if (!formValues.parents[0].addressZip) {
      parentErrors.addressZip = "You must enter a zip code";
    }
    returnArray[0] = parentErrors;
    errors.parents = returnArray;
  }

  if (!formValues.emergencyContactName) {
    errors.emergencyContactName = "You must enter a name";
  }

  if (!formValues.emergencyContactPhone) {
    errors.emergencyContactPhone = "You must enter a phone number";
  }

  if (!formValues.fairfaxCountyResident) {
    errors.fairfaxCountyResident = "You must answer this question";
  }

  if (!formValues.militaryFamily) {
    errors.militaryFamily = "You must answer this question";
  }

  if (!formValues.soccerField) {
    errors.soccerField = "You must answer this question";
  }

  let tryingToRegisterForFullTeam = false;
  if (formValues.children) {
    formValues.children.map((child) => {
      formValues.children.forEach((child) => {
        if (Array.isArray(ageGroupToFieldMapping[child.teamByAgeGroup]) && ageGroupToFieldMapping[child.teamByAgeGroup].includes(formValues.soccerField)) {
          tryingToRegisterForFullTeam = true;
        }
      });
    });
  }

  if (tryingToRegisterForFullTeam) {
    errors.soccerField =
      "Sorry, this field is full for the teams you selected. Please select another field.";
  }

  if (!(formValues.statementOfFaith === "yes")) {
    errors.statementOfFaith =
      "You must read and agree to the Statement of Faith or Nicene Creed";
  }

  if (!(formValues.codeOfConduct === "yes")) {
    errors.codeOfConduct =
      "You must read and agree to the Code of Conduct and Medical Release";
  }

  return errors;
};

export default reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(SignupForm);

const renderYesNoWithLabel = (fieldName, fieldLabel) => {
  return (
    <div>
      <label>{fieldLabel}</label>
      <br />
      <label className="yesNoLabel">
        <Field name={fieldName} component="input" type="radio" value="yes" />{" "}
        Yes{" "}
      </label>
      <label className="yesNoLabel">
        <Field name={fieldName} component="input" type="radio" value="no" /> No{" "}
      </label>
    </div>
  );
};

const renderYesNo = (fieldName) => {
  return (
    <div>
      <label className="yesNoLabel">
        <Field name={fieldName} component="input" type="radio" value="yes" />{" "}
        Yes{" "}
      </label>
      <label className="yesNoLabel">
        <Field name={fieldName} component="input" type="radio" value="no" /> No{" "}
      </label>
    </div>
  );
};
