exports.calculate = ({ childCount, tshirtCount }) => {
  const childCountNum = parseInt(childCount);
  const tshirtCountNum = parseInt(tshirtCount);
  let childrenCost = 0;

  if (!childCountNum || childCountNum === 0) {
    childrenCost = 0;
  } else if (childCountNum === 1) {
    childrenCost = 43;
  } else if (childCountNum === 2) {
    childrenCost = 81;
  } else {
    childrenCost = 81 + 33 * (childCountNum - 2);
  }

  const tshirtCost = tshirtCount ? tshirtCountNum * 15 : 0;

  return childrenCost + tshirtCost;
};

/**
 * body is a JSON that has key children
 */
exports.countChildren = (body) => {
  if (!body || !body["children"]) return 0;
  const children = body["children"];
  const reducer = (accumulator, child) => accumulator + isChildPlaying(child);
  return children.reduce(reducer, 0);
};

const isChildPlaying = (child) => (child["position"] === "playing" ? 1 : 0);

/**
 * body is a JSON that has key children
 */
exports.countTshirts = (body) => {
  if (!body || !body["children"]) return 0;
  const children = body["children"];
  const reducer = (accumulator, child) => accumulator + tshirtCount(child);
  return children.reduce(reducer, 0);
};

const tshirtCount = (child) => {
  if (child["shirtSize"] === undefined) {
    return 0;
  } else if (child["shirtSize"] == null) {
    return 0;
  } else if (child["shirtSize"] === "None") {
    return 0;
  } else if (child["shirtSize"] === "") {
    return 0;
  } else {
    return 1;
  }
};
